<template>
    <div class="play-box" id="demo" :style="{'display': ($parent.info.module == 'game') ? 'block': 'none'}">
      <div class="game-box"  style="background: #010001;" @click="isFocus = false">
      </div>
      <div class="setting-box">
        <div
          class="setting-btn btn"
          :class="{ active: isHover }"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
        >
          <span
            class="name"
            @click="
              isFocus = !isFocus;
              isHover = false;
            "
            >打开菜单</span
          >
        </div>
        <div class="btn-msg"><span class="ping s-0">{{network_log.latency}}ms </span></div>
        <div class="setting-list" :class="{ focus: isFocus }">
          <div class="top">
            <div class="gamestate-box">
              <div class="userinfo">
                <div class="avatar flex yc">
                  <span class="name"
                    >剩余时长：<span class="free-time"
                      ><span style="display: inline-block;" v-if="$parent.game_info.members_time == ''">{{$parent.game_info.length}}分钟</span> <span style="display: inline-block;"  v-else>{{$parent.game_info.members_time}}</span></span
                    ></span
                  >
                </div>

                <div class="vip-time">
                 
                </div>
                <!-- <div class="vip-time" v-if="$parent.game_info.login_info.login == 0">
                  <span>暂未开通会员，开通后享会员特权</span>
                </div>
                <div class="vip-time" v-if="$parent.game_info.login_info.login == 1">
                  <span>您已登录，查看我获得更多特权</span>
                </div> -->
                
                <!-- <div class="link">
                  <div class="right" style="position: absolute;top: 42px;right: 18px;">
                    <button class="btn vip" stat-id="setting.vipcenter" @click="$parent.Login" v-if="$parent.game_info.login_info.login == 0">
                      开通会员
                    </button>
                    <button class="btn vip" stat-id="setting.vipcenter" @click="$parent.Personal" v-if="$parent.game_info.login_info.login == 1">
                      个人中心
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="setting-menu">
              <ul>
                <li class="i-0" @click="phone_ty = true">
                    <div class="name flex xlr">手机体验</div>
                    <div class="extra">
                    <img
                        class="icon"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADrSURBVHgB7dgxDsIwDAXQhIkjcASOwEZvwZizcQxuxMjYLdiQLh3Asf/PAP2SFalK1Se7UqSktOVXU2vdSxWpQyImWzYpRpYipZhZ6ppzvidCdsZ9l4bRvHCsTllBt/TuzBIayjQyTft4aZgl8PGZQaNQXaARqG4QG+UCMVFuEAsVAjFQYRAaBQEhUTAQCgUFIVBwUBRlPVx7c1xhNI9WH8MY2STLefVYu6Ldmb+9j/6ppwgGCkJgYCAUBgJCYsIgNCYEYmDcIBbGBWJiukFsTBdoBMYMGoXReA9XCkbjGRkN0x1BndpNyP/kCcptuX9qx4JcAAAAAElFTkSuQmCC"
                        alt=""
                    />
                    </div>
                </li>
                <li class="i-1" >
                    <div class="name flex xlr">游戏全屏</div>
                    <div class="extra">
                      <el-switch
                        @change="ScreenCilke"
                        v-model="Screen">
                      </el-switch>
                    </div>
                </li>
                <li class="i-2">
                    <div class="name flex xlr">网络信息</div>
                    <div class="extra">
                      <el-switch
                        v-model="network_log.network">
                      </el-switch>
                    </div>
                </li>
                <div class="split"></div>
                <li class="i-3" v-if="$parent.game_info.registered != '' && $parent.game_info.registered != null" @click="$parent.url_address($parent.game_info.registered, 'registered')">
                    <div class="name flex xlr">注册游戏</div>
                    <div class="extra">
                    <img
                        class="icon"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADrSURBVHgB7dgxDsIwDAXQhIkjcASOwEZvwZizcQxuxMjYLdiQLh3Asf/PAP2SFalK1Se7UqSktOVXU2vdSxWpQyImWzYpRpYipZhZ6ppzvidCdsZ9l4bRvHCsTllBt/TuzBIayjQyTft4aZgl8PGZQaNQXaARqG4QG+UCMVFuEAsVAjFQYRAaBQEhUTAQCgUFIVBwUBRlPVx7c1xhNI9WH8MY2STLefVYu6Ldmb+9j/6ppwgGCkJgYCAUBgJCYsIgNCYEYmDcIBbGBWJiukFsTBdoBMYMGoXReA9XCkbjGRkN0x1BndpNyP/kCcptuX9qx4JcAAAAAElFTkSuQmCC"
                        alt=""
                    />
                    </div>
                </li>
                <li class="i-4" v-if="$parent.game_info.address != '' && $parent.game_info.address != null" @click="$parent.url_address($parent.game_info.address, 'address')">
                    <div class="name flex xlr">游戏下载</div>
                    <div class="extra">
                    <img
                        class="icon"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADrSURBVHgB7dgxDsIwDAXQhIkjcASOwEZvwZizcQxuxMjYLdiQLh3Asf/PAP2SFalK1Se7UqSktOVXU2vdSxWpQyImWzYpRpYipZhZ6ppzvidCdsZ9l4bRvHCsTllBt/TuzBIayjQyTft4aZgl8PGZQaNQXaARqG4QG+UCMVFuEAsVAjFQYRAaBQEhUTAQCgUFIVBwUBRlPVx7c1xhNI9WH8MY2STLefVYu6Ldmb+9j/6ppwgGCkJgYCAUBgJCYsIgNCYEYmDcIBbGBWJiukFsTBdoBMYMGoXReA9XCkbjGRkN0x1BndpNyP/kCcptuX9qx4JcAAAAAElFTkSuQmCC"
                        alt=""
                    />
                    </div>
                </li>
                <li class="i-5" v-if="$parent.game_info.qroup != '' && $parent.game_info.qroup != null" @click="$parent.url_address($parent.game_info.qroup, 'qroup')">
                    <div class="name flex xlr">游戏群聊</div>
                    <div class="extra">
                    <img
                        class="icon"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADrSURBVHgB7dgxDsIwDAXQhIkjcASOwEZvwZizcQxuxMjYLdiQLh3Asf/PAP2SFalK1Se7UqSktOVXU2vdSxWpQyImWzYpRpYipZhZ6ppzvidCdsZ9l4bRvHCsTllBt/TuzBIayjQyTft4aZgl8PGZQaNQXaARqG4QG+UCMVFuEAsVAjFQYRAaBQEhUTAQCgUFIVBwUBRlPVx7c1xhNI9WH8MY2STLefVYu6Ldmb+9j/6ppwgGCkJgYCAUBgJCYsIgNCYEYmDcIBbGBWJiukFsTBdoBMYMGoXReA9XCkbjGRkN0x1BndpNyP/kCcptuX9qx4JcAAAAAElFTkSuQmCC"
                        alt=""
                    />
                    </div>
                </li>
                <li class="i-6" @click="$parent.GameLeave">
                    <div class="name flex xlr">退出游戏</div>
                    <div class="extra">
                    <img
                        class="icon"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADrSURBVHgB7dgxDsIwDAXQhIkjcASOwEZvwZizcQxuxMjYLdiQLh3Asf/PAP2SFalK1Se7UqSktOVXU2vdSxWpQyImWzYpRpYipZhZ6ppzvidCdsZ9l4bRvHCsTllBt/TuzBIayjQyTft4aZgl8PGZQaNQXaARqG4QG+UCMVFuEAsVAjFQYRAaBQEhUTAQCgUFIVBwUBRlPVx7c1xhNI9WH8MY2STLefVYu6Ldmb+9j/6ppwgGCkJgYCAUBgJCYsIgNCYEYmDcIBbGBWJiukFsTBdoBMYMGoXReA9XCkbjGRkN0x1BndpNyP/kCcptuX9qx4JcAAAAAElFTkSuQmCC"
                        alt=""
                    />
                    </div>
                </li>
              </ul>
              <div style="text-align: center;margin-top: 22%;">
                <el-button size="mini"  @click="isFocus = false">关闭展示</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="network_log.network" style="position: absolute;color: #6a4343;right: 12px;top: 8px;z-index: 20000;" >
        <span>延迟：{{network_log.latency}}ms</span>
        <span style="margin-left: 10px;">码率：{{network_log.biterate}}kbps</span>
        <span style="margin-left: 10px;">帧率：{{network_log.FPS}}FPS</span>
        <span style="margin-left: 10px;">丢率：{{network_log.packetLossRate}}%</span>
      </div>
      <!-- <login-model /> -->
      <!-- 进度 -->
      <el-dialog title="启动进度"
                 :visible.sync="progressDialogVisible"
                 :close-on-click-modal="false"
                 :close-on-press-escape="false"
                 :show-close="false"
                 width="30%"
                 :before-close="progressHandleClose">
        <div>
          <p style="margin-bottom: 10px;">温馨提示：卡在90%，代表正在加载资源，属于正常状态，一般20秒内完成</p>
          <el-progress :text-inside="true"
                       :stroke-width="24"
                       :percentage="percentage"
                       status="success"></el-progress>
        </div>
      </el-dialog>

      <!-- 扫码 -->
      <el-dialog
        title="微信扫一扫"
        :visible.sync="phone_ty"
        width="20%"
        :before-close="phoneClose">
        <div style="text-align:center;">
          <vue-qr :size="300"
                          :text="this.$parent.game_info.phone_url" />
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
 import { Launcher } from "live-dragon";
//import { screenfull } from "ray-streaming";
import screenfull from 'screenfull'  
import Vue from "vue";
import vueQr from "vue-qr";
  export default {
    name: "GamePcGameModel",
    components: {
      vueQr,
    },
    data() {
      return {
        isHover: false,
        isFocus: false,
        percentage:0,
        progressDialogVisible:false,
        percentagetimer: null,
        Screen:false, //全屏状态
        phone_ty:false,
        network_log: {
          network:true,
          latency: 0,
          biterate: 0,
          FPS: 0,
          packetLossRate: 0.00,
          averageJitterBufferDelay: 0,
        },
        launcher: null,
      };
    },
    mounted() {
      this.ScreenInit()
    },
    methods: {
           //启动云游戏
        CloudStart () {
          this.ScreenCilke();
          var container = document.getElementById("demo");
          this.launcher = new Launcher(
            `${this.$parent.cloud_config.address}/clientWebsocket/${this.$parent.cloud_config.token}`,
            this.$parent.cloud_config.iceServers,
            container,
            {
              enableClipboard: false, onPhaseChange: (phase) => {
                if (phase === 'loaded-metadata') {
                  // 这时候视频流开始播放 关闭进度
                  this.percentage = 100;
                }
              }
            }
          );
          this.progressDialogVisible = true
          this.ProgressTask()
          this.toggleStatistics()
        },
        progressHandleClose(){
          this.progressDialogVisible = false
        },
        //启动一个进度定时
      ProgressTask () {
        this.percentagetimer = setInterval(() => {
          //设置延迟执行
          if (this.percentage >= 100) {
            clearInterval(this.percentagetimer);
            this.progressHandleClose()
          } else {
            if (this.percentage < 90) {
              this.percentage += 10;
            }
          }
          this.MonitoringGame(); //定时监控游戏进程
        }, 1000);
       
      },
      MonitoringGame(){
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.weburl + "/cloud/MonitoringGame",
          // 传递参数
          data: {
            key: this.$route.query.key,
            token: localStorage.getItem("token"),
          },
          responseType: "json",
        })
          .then((response) => {
            if(response.data.code != 200){
              this.progressHandleClose()
              clearInterval(this.percentagetimer);
              this.$confirm("游戏进程启动异常，请重新启动", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$parent.GameLeave();
                })
                .catch(() => { });
            }
           
          })
          .catch((error) => {
            // 请求失败，
            console.log(error);
          });
      },
      //全屏开关
      ScreenCilke(){
        screenfull.toggle(); // 切换全屏状态
      },
      //监控全屏状态
      ScreenInit() {
        if (screenfull.isEnabled) {
          screenfull.on('change', this.ScreenChange)		// 开启监听
        }
      },
      ScreenChange() {
        this.Screen = screenfull.isFullscreen;// 更新全屏状态
      },
      //显示网络状态
      toggleStatistics () {
        this.launcher.toggleStatistics()
        window.setInterval(() => {
          const { fps, bitrate, packetLossRate, latency, averageJitterBufferDelay } = this.launcher.report()
          this.network_log.FPS = fps
          this.network_log.biterate = bitrate
          this.network_log.latency = latency
          this.network_log.packetLossRate = (packetLossRate * 100).toFixed(3)
          this.network_log.averageJitterBufferDelay = averageJitterBufferDelay
        }, 1000)
      },
      Network(){
        if(this.network_log.network){
          this.network_log.network = false;
        }else{
          this.network_log.network = true;
        }
      },
      phoneClose(){
        this.phone_ty = false;
      },
    },
  };
  </script>
  <style scoped lang="less">
  .play-box {
    /deep/ .el-dialog__wrapper .el-dialog {
      margin-top: 17% !important;
      //position: inherit !important;
    }
    width: 100vw;
    .setting-box {
      position: absolute;
      top: 90px;
      left: 0;
      font-size: 12px;
      z-index: 10000;
      .setting-list {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 101;
        width: 385px;
        height: 100%;
        transform: translate(-385px);
        transition: all 0.3s linear;
        background: linear-gradient(
            179.92deg,
            rgba(255, 255, 255, 0.05) 0.07%,
            rgba(255, 255, 255, 0) 98.68%
          ),
          rgba(19, 36, 45, 0.95);
        box-shadow: 16px 0 28px #00000040, 0 3px 28px #0000001f,
          0 6px 16px #00000014, 0 9px 28px #0000000d;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .setting-menu {
          padding: 30px 24px;
          box-sizing: border-box;
          li {
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: left 16px center;
            position: relative;
            padding: 0 15px;
            height: 38px;
            line-height: 38px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 52px;
            width: 338px;
            height: 52px;
            margin-top: 0;
            .name {
              font-weight: 500;
              font-size: 16px;
              line-height: 52px;
              color: #fff;
            }
            .extra {
              .icon {
                width: 12px;
              }
            }
          }
          li:hover {
            background-color: #ffffff1a;
            border-radius: 8px;
          }
          .split {
            margin: 32px auto;
            width: 306px;
            border-bottom: 1px solid rgba(217, 217, 217, 0.1);
          }
          .i-0 {
            background-image: url(../../../../public/static/cloud/img/ioc/phone.png);
          }
          .i-1 {
            background-image: url(../../../../public/static/cloud/img/ioc/qp.png);
          }
          .i-2 {
            background-image: url(../../../../public/static/cloud/img/ioc/wl.png);
          }
          .i-3 {
            background-image: url(../../../../public/static/cloud/img/ioc/game.png);
          }
          .i-4 {
            background-image: url(../../../../public/static/cloud/img/ioc/xz.png);
          }
          .i-5 {
            background-image: url(../../../../public/static/cloud/img/ioc/QQ.png);
          }
          .i-6 {
            background-image: url(../../../../public/static/cloud/img/ioc/tc.png);
          }
        }
        .userinfo {
          box-sizing: border-box;
          padding: 29px 0 0;
          background: #263036;
          border-bottom: 1px solid #35444b;
          .avatar {
            width: 336px;
            margin: 0 auto;
          }
          .name {
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            color: #fff;
          }
          .free-time {
            color: #00ff94;
          }
          var {
            font-style: normal;
            font-weight: 600;
          }
          .vip-time {
            width: 336px;
            margin: 12px auto;
            text-align: left;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #ffffffb3;
          }
          .rights {
            margin-top: 25px;
            padding: 0 24px;
            display: flex;
            justify-content: space-between;
            .item {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              color: #ffffff80;
              img {
                width: 24px;
                height: 24px;
                margin-bottom: 6px;
              }
            }
          }
          .link {
            .btn {
              background-color: #2e2e2e;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              text-decoration: none;
              outline: none;
              border: none;
              text-align: center;
              cursor: pointer;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              padding: 0;
              margin: 0;
              width: 68px;
              height: 20px;
              line-height: 17px;
              width: 80px;
              height: 30px;
              border-radius: 24px;
              background: rgba(255, 255, 255, 0.1);
              border: 1px solid rgba(255, 255, 255, 0.1);
              font-size: 14px;
              font-weight: 400;
            }
            .btn.vip {
              font-weight: 500;
              color: #291f10;
              background: linear-gradient(
                90.7deg,
                #ffebbe 0.63%,
                #ffa964 67.39%,
                #f2c561 104.98%
              );
              border-radius: 32px;
              border: none;
            }
          }
        }
      }
      .focus.setting-list {
        transform: translate(0);
      }
      .btn-msg {
        height: 32px;
        display: flex;
        align-items: center;
        background: rgba(26, 48, 59, 0.5);
        border: 1px solid rgba(14, 26, 33, 0.1);
        box-shadow: 0 8.54546px 19.2273px #00000040;
        border-radius: 9px;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.009em;
        color: #00ff85;
        padding: 0 11px 0 75px;
        white-space: nowrap;
        position: absolute;
        left: 5px;
        top: 20px;
        transform: translate(-60px);
        transition: all 0.2s linear;
        pointer-events: none;
        .ping {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJxSURBVHgB7ZhdctMwFIWPU6a8dgneAWUFmBVQVkB3gPsKYRJPm/LYZgWYFRBWgLsCwg7MDvJKw1gcyTFOG8uOZLmdzuibUez4R9LRvVe6MuDxeDwezxMmgAvE5Ah4FrG6Fywhr4Sb4/ZD+ea45D2eF78QfMrQE3sB4jzi62+A0Qn/hLAno5gU+HuDIMlhiJkANdKHMc/YcRzDPQuKmZtYZj8BdcffsxxheHI2GiMYf+96sFuAOKeLHFwZukle+/yd5qTVTAYgBW6TNtfSC1Cj/vwLT07Qzopmp+kPMh4zjtrv1qfLehns4risO4jQWb+giPE19hYgPof8+dE+6iJjAE8RfLhBH1RbRcSuTNrbC67Z1tnOVX3FMwpA1HAn7TKrNWJ22iKEMfFxfv9ii4AdK8j5O+494vsgLuJSSBUvgpYeJ02PtgexmMmgkyJSVnCGh+T/ABY5234Na1RFj4gKej1uUomdBg9D7Cx0HEmMVvTjJRziMBdSC90rNAf+NpwWVfqw4Ir7FT3pJ0DlQyMZbBHsyFko4pZTZLKCBXYCyhGfokwtXJDTIlMbi5gLKFfSnz0zUF3l2ulSxwimKFPL9LcR6QZMK7joqDRBipRFpgvqv0wHlvp31w9ggQpx+W0rT1qVnVvP9/LlMn2QsXNaXyze0oUWMKSHgMqVZCK3TqyCsF6sUlPXcUPHIuPxPAHc50ISldfjHcp8SMbJsizu9xEDJHPajdAGu+lSh/lC1knRNh3mLjsvcS9AfdMRTZufnC7UY2OiaQ5DIS6v1Ledmpeu9wKSAVyo4o90parD8RCdHx6ZKoiLCTwej8fj8TTzDy1C3AulRcXtAAAAAElFTkSuQmCC);
          background-size: 14px;
          background-position: left center;
          background-repeat: no-repeat;
          padding-left: 20px;
        }
      }
      .btn {
        background-color: #2e2e2e;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-decoration: none;
        outline: none;
        border: none;
        text-align: center;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        padding: 0;
        margin: 0;
      }
      .setting-btn {
        background-color: #1a303b;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        outline: none;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.2);
        transform: translate(-60px);
        transition: all 0.2s linear;
        .icon-right {
          width: 4px;
          position: absolute;
          right: 3px;
          top: 50%;
          transform: translateY(-50%);
        }
        .icon-logo {
          width: 30px;
          position: absolute;
          right: 14px;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.3s linear;
        }
        .name {
          font-weight: 600;
          font-size: 14px;
          line-height: 72px;
          text-align: center;
          color: #01b0fb;
          text-shadow: 0px 0px 15.3191px rgb(0 198 255 / 76%);
          display: none;
          transition: all 0.3s linear;
        }
      }
      .setting-btn.active {
        background-size: 100%;
        background-image: url(../../../../public/static/cloud/img/s/btn-bg.png);
        background-position: center;
        transform: translate(0);
        .icon-right {
          display: none;
        }
        .icon-logo {
          display: none;
          opacity: 0;
        }
        .name {
          font-size: 12px;
          opacity: 1;
          display: block;
        }
      }
      .setting-btn.active + .btn-msg {
        background: rgba(26, 48, 59, 0.9);
        transform: translate(0);
      }
    }
    .game-box {
      width: 100%;
      height: 100vh;
      position: relative;
      margin: 0 auto;
      .start-media-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: fill;
        object-fit: fill;
      }
    }
  }
  </style>
