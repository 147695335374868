<template>
    <div id="BodyApp">
        <el-dialog title="登陆"
                 :visible.sync="LoginDialogVisible"
                 :modal="false"
                 width="32%"
                 :close-on-click-modal="false"
                 :before-close="LoginClose"
                 class="login-web">
        <div v-loading="Loading" class="Cloud-login">
            <el-form ref="form"
                        :model="login_form">
                <el-form-item>
                <el-input v-model="login_form.phone"
                            placeholder="手机号"
                            @change="change()"></el-input>
                </el-form-item>
                <el-form-item id="ImageGame">
                    <el-input v-model="login_form.verification"
                                placeholder="图形码">
                    <el-image style="width: 115px" slot="append" class="phone-code"
                    :src="codeurl"
                    @click="getCode" />
                </el-input>
                
                </el-form-item>
                <el-form-item>
                <el-input v-model="login_form.code"
                            placeholder="短信码">
                    <el-button slot="append"
                                @click="GetSmsCode"
                                v-if="smscode <= 0">
                    获取
                    </el-button>
                    <el-button slot="append"
                                v-else-if="smscode > 0">
                    {{ smscode }}
                    </el-button>
                </el-input>
                </el-form-item>
                <div>
                <el-button type="primary"
                            style="width: 100%"
                            @click="CloudLogin">
                    登陆/注册
                </el-button>
                </div>
            </el-form>
          </div>
      </el-dialog>
    </div>
    <!-- 登陆 -->
   
</template>

<script>
  import Vue from "vue";
export default {
  name: "LoginPcModel",
  data () {
    return {
      Loading: false,
      LoginDialogVisible:false,
      codeurl: '',
      smscode:0,
      login_form:{
        phone:"", //手机号
        verification:"", //图形码
        code:"", //短信码
        verifkey:"", //图形码key
      },
    };
  },
  methods: { 
    //设置登陆token
    setToken (token) {
      localStorage.token = token; //同步存储token至localStorage
    },
    LoginShow(){
        this.LoginDialogVisible = true;
        this.getCode();
    },
    LoginClose(){
        Object.assign(this.$data, this.$options.data());
        this.LoginDialogVisible = false;
    },
    //获取图形码
    getCode () {
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.weburl + "/code",
          // 传递参数
          data: {},
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false
            if (response.data.code == 200) {
              this.codeurl = response.data.result.img
              this.login_form.verifkey = response.data.result.key
            }
          })
          .catch((error) => {
            this.Loading = false
            // 请求失败，
            console.log(error);
          });
    },
    //获取验证码
    GetSmsCode(){
        if (this.Loading == true) {
          return false;
        }
        if (this.login_form.phone == "") {
          this.$message({
            message: "请输入手机号",
            type: "warning",
          });
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.weburl + "/cloud/getCloudSmsCode",
          // 传递参数
          data: {
            key: this.$route.query.key,
            verification: this.login_form.verification,
            verifkey: this.login_form.verifkey,
            phone: this.login_form.phone,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.$message({
                message: response.data.msg,
                type: "success",
              });
              this.smscode = 120;
              clearInterval(this.smstimer);
              this.smstimer = setInterval(() => {
                //设置延迟执行
                this.smscode--;
                if (this.smscode <= 0) {
                  clearInterval(this.smstimer);
                }
              }, 1000);
            } else {
              this.$message({
                message: response.data.msg,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
    },
    //登录
    CloudLogin(){
        if (this.Loading == true) {
          return false;
        }
        if (this.login_form.phone == "") {
          this.$message({
            message: "请输入手机号",
            type: "warning",
          });
          return false;
        }
        if (this.login_form.code == "") {
          this.$message({
            message: "请输入短信码",
            type: "warning",
          });
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.weburl + "/cloud/CloudLogin",
          // 传递参数
          data: {
            key: this.$route.query.key,
            phone: this.login_form.phone,
            code: this.login_form.code,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.$message({
                message: response.data.msg,
                type: "success",
              });
              this.setToken(response.data.token);
              this.LoginClose();
              location.reload();
            } else {
              this.$message({
                message: response.data.msg,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
    },
  },
};
</script>
  
<style lang="scss" scoped>
#ImageGame {
  /deep/ .el-input-group__append {
   padding: 0px 0px !important;
  }
}
#BodyApp{
    /deep/ .el-dialog__title{
        color: #6a700c!important;
    }
    /deep/ .el-dialog__body{
        padding: 30px 100px !important;
    }
}
</style>