<template>
    <div id="BodyApp">
        <el-dialog title="个人中心"
                 :visible.sync="DialogVisible"
                 :modal="false"
                 width="32%"
                 :close-on-click-modal="false"
                 :before-close="Close"
                 class="login-web">
        <div v-loading="Loading" class="Cloud-login">
            <el-tabs v-model="activeName" tab-position="left">
                <el-tab-pane label="我的信息" name="config">
                    <div>
                        <el-form ref="form" :model="form" label-width="80px">
                            <el-form-item label="我的账户">
                                <span>{{ $parent.game_info.login_info.user }}</span>
                            </el-form-item>
                            <el-form-item label="会员到期">
                              <span v-if="info.members_time == '非会员'">非会员 <span style="color: #f00;font-size: 12px;">(本服剩余限免：{{ $parent.game_info.length-info.length}}分)</span></span>
                              <span v-else>{{ info.members_time }} <span style="color: #f00;font-size: 12px;">(会员用户不限时长)</span></span>
                            </el-form-item>
                            <el-form-item label="注册时间">
                                <span>{{ info.time }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="游戏记录" name="gamelog">
                    <div>
                        <el-table
                        :data="info.gamelog"
                        height="250"
                        style="width: 100%">
                        <el-table-column
                            prop="name"
                            label="游戏名称" align="center">
                        </el-table-column>
                        <el-table-column
                            prop="length"
                            label="总花费时长（分）" align="center">
                        </el-table-column>
                        <el-table-column align="center"  label="操作">
                            <template #default="{ row }">
                                <el-button
                                size="small"
                                type="text"
                                @click="url_address(row.cloud_url)"
                                >
                                进入游戏
                                </el-button>
                            </template>
                        </el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="会员开通" name="lengthPay">
                    <div>
                        <el-alert
                            title="温馨提示：会员不限时长，全服通用 开通遇到问题联系QQ：2889046441"
                            type="success">
                        </el-alert>
                        <el-form ref="form" :model="form" label-width="80px">
                          <el-form-item label="开通券" style="margin-top: 10px;">
                                <el-input v-model="form.cdk">
                                    <el-button slot="append" @click="GetCdk">获取</el-button>
                                </el-input>
                            </el-form-item>
                            <div style="text-align: center;">
                                <el-button type="primary" @click="onSubmit">立即开通</el-button>
                            </div>
                        </el-form>
                    </div>
                </el-tab-pane>
            </el-tabs>
          </div>
      </el-dialog>
    </div>
    <!-- 登陆 -->
   
</template>

<script>
import Vue from "vue";
export default {
  name: "PersonalPcModel",
  data () {
    return {
        activeName:'config',
        Loading: false,
        DialogVisible:false,
        info:{
            gamelog:[], //游戏记录
            length:0,
            time:"",
            members_time:'非会员',
        },
        form:{
            cdk:"",
        },
    };
  },
  methods: { 
    Show(){
        this.DialogVisible = true;
        this.getInfo();
    },
    Close(){
        Object.assign(this.$data, this.$options.data());
        this.DialogVisible = false;
    },
    //获取个人中心
    getInfo(){
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.weburl + "/cloud/getPersonalInfo",
          // 传递参数
          data: {
            key: this.$route.query.key,
            token: localStorage.getItem("token"),
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.info = response.data.info;
            } else {
              this.$message({
                message: response.data.msg,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
    },
    url_address (url) {
      window.open(url)
    },
    //兑换
    onSubmit(){
        if(this.Loading == true){
            return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.weburl + "/cloud/cloudExchangeCdk",
          // 传递参数
          data: {
            key: this.$route.query.key,
            token: localStorage.getItem("token"),
            cdk:this.form.cdk,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
                this.$message({
                    message: response.data.msg,
                    type: "success",
                });
                location.reload();
            } else {
              this.$message({
                message: response.data.msg,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
    },
    GetCdk(){
      window.open("http://179.76676.com/category/A6307259DCD2B332","_blank")
    },
  },
};
</script>
  
<style lang="scss" scoped>
#ImageGame {
  /deep/ .el-input-group__append {
   padding: 0px 0px !important;
  }
}
#BodyApp{
    /deep/ .el-dialog__title{
        color: #6a700c!important;
    }
}
</style>